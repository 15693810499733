/*
 * @Auth: linjituan
 * @Date: 2021-12-20 17:08:16
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-21 17:03:44
 */
// 审批详情(包含审批)页面配置
const open = function() {
  return [
    {
      modules: [
        {
          template: 1,
          id: 'expenses',
          refName: 'expenses',
          component: () => import('./modules/expenses')
        },
        {
          template: 2,
          id: 'recharge',
          refName: 'recharge',
          component: () => import('./modules/oilAccountRecord/rechargeForm')
        },
        {
          template: 3,
          id: 'contract',
          refName: 'contract',
          component: () => import('./modules/contract')
        }
      ]
    }
  ]
}
export const openModules = vm => {
  return open.call(vm)
}
