<!--
 * @Auth: linjituan
 * @Date: 2021-12-20 17:08:16
 * @LastEditors: linjituan
 * @LastEditTime: 2022-01-13 18:29:45
-->
<template>
  <div v-if="isShow">
    <component
      :is="currentComponent.component"
      :ref="currentComponent.refName"
      :refName="currentComponent.refName"
      :approvalRecord="approvalRecord"
      @componentClose="handleClose"
      @ok="handleOk"
      :isShow="isShow"
      :action="action"
    ></component>
  </div>
</template>
<script>
import { openModules } from './config'
import base from '../base'
const vm = this
export default {
  name: 'ApprovalDetail',
  mixins: [base],
  data() {
    return {
      openList: [],
      openListValue: [],
      title: '',
      currentComponent: {},
      approvalRecord: null,
      isShow: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleClose() {
      console.log('handleClose')
      this.isShow = false
    },
    handleOk() {
      this.$emit('ok')
    },
    // 查看业务申请详情, 第二个参数是动作，如果action=reject代表是撤回
    // 查看详情共性包含撤回与审批节点
    // 不同的详情要调用不同的业务申请接口
    async handleDetail(approvalRecord, action) {
      console.log('handleDetail', approvalRecord)
      this.action = action
      this.approvalRecord = approvalRecord
      const templateId = await this.getTemplateId(approvalRecord)
      const tmpArr = this.openListValue.filter(p => p.template === templateId)
      if (tmpArr.length === 1) {
        console.log('找到组件配置', approvalRecord.approvalKind)
        this.currentComponent = tmpArr[0]
        this.isShow = true
      } else {
        console.log('找不到组件配置', approvalRecord.approvalKind)
        this.currentComponent = {}
      }
    },
    getList() {
      this.openList = openModules(vm)[0]
      this.openListValue = this.openList.modules
    }
  }
}
</script>
